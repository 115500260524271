<template>
  <div style="text-align: center" class="p-4" v-if="loadingStatus.isError">Ошибка при загрузке данных</div>
  <div class="p-4" v-else>
    <div class="text-muted mb-4 d-flex align-items-center">
      <font-awesome-icon
          class="mr-2 update-icon"
          style="font-size: 12px;"
          :icon="['fas', 'circle-notch']"
          v-show="autoUpdate"
      />
      {{ autoUpdate ? 'Автообновление включено' : 'Автообновление приостановлено' }}
    </div>
    <div @scroll="onLogsScroll" style="height: 300px; overflow-y: auto;">
      <div class="mb-4" v-for="log, index in logs" :key="'log' + index">
        <p style="font-size: 12px;" class="text-muted m-0">{{ log.date | toDate }}</p>
        <p style="word-break: break-word;" class="m-0">{{ log.action }}</p>
      </div>
      <p v-show="!loadingStatus.isDone">...</p>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import * as workerTimers from 'worker-timers';

export default {
  name: "UserLogs",
  props: {
    userId: String
  },
  data() {
    return {
      logs: [],
      isLogsAreLoading: false,
      currentLogsCount: 0,
      isLogsEnded: false,
      loadingStatus: { isDone: true, isError: false },
      updateTimer: null,
      autoUpdate: true,
    }
  },
  methods: {
    async onLogsScroll(el) {
      if (el.srcElement.scrollTop === 0) {
        this.autoUpdate = true;
        this.isLogsEnded = false;
        this.currentLogsCount = 0;
        await this.loadUserLogs();
        this.updateTimer = workerTimers.setInterval(this.loadUserLogs, 5000);
        return
      }
      if (el.srcElement.scrollTop !== 0) {
        this.autoUpdate = false;
        this.stopInterval();
      }
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) this.loadUserLogs();
    },
    async loadUserLogs() {
      try {
        if (this.isLogsEnded || !this.loadingStatus.isDone) return;
        if (this.autoUpdate) this.currentLogsCount = 0;
        this.loadingStatus.isDone = false;
        const fetchedData = await axios.post('backoffice/admin/user/logs', { userId: this.userId, offset: this.currentLogsCount });
        this.currentLogsCount = this.logs.length;
        if (this.autoUpdate) {
          this.logs = fetchedData.data.userLogs;
        } else {
          this.logs = this.logs.concat(fetchedData.data.userLogs);
          if (fetchedData.data.error) return this.loadingStatus.isError = true;
          if (this.currentLogsCount === this.logs.length) this.isLogsEnded = true;
        }
        this.loadingStatus.isDone = true;
      } catch (e) {
        console.log(e)
        this.loadingStatus.isError = true;
        this.stopInterval();
      }
    },
    stopInterval() {
      if (this.updateTimer === null) return;
      workerTimers.clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
  },
  mounted() {
    this.loadUserLogs();
    this.updateTimer = workerTimers.setInterval(this.loadUserLogs, 5000);
  },
  destroyed() {
    this.stopInterval();
  }
}
</script>

<style scoped>

.update-icon {
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>